import React, { useState, useEffect } from 'react';
import { RefreshCw, Wifi, WifiOff } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from './components/ui/card';
import Alert, { AlertDescription } from './components/ui/alert';

// Add category mapping constant
const SYMBOL_CATEGORIES = {
  FOREX: [
    "AUDCADraw", "AUDCHFraw", "AUDJPYraw", "AUDNZDraw", "AUDUSDraw",
    "CADCHFraw", "CADJPYraw", "CHFJPYraw", "EURAUDraw", "EURCADraw",
    "EURCHFraw", "EURGBPraw", "EURHKDraw", "EURJPYraw", "EURNOKraw",
    "EURNZDraw", "EURSGDraw", "EURTRYraw", "EURUSDraw", "GBPAUDraw",
    "GBPCADraw", "GBPCHFraw", "GBPJPYraw", "GBPNZDraw", "GBPUSDraw",
    "HKDJPYraw", "NZDCADraw", "NZDCHFraw", "NZDJPYraw", "NZDUSDraw",
    "USCUSDraw", "USDCADraw", "USDCHFraw", "USDCNHraw", "USDHKDraw",
    "USDJPYraw", "USDMXNraw", "USDNOKraw", "USDSEKraw", "USDSGDraw",
    "USDTRYraw", "USDZARraw"
  ],
  INDEX_CFDs: [
    "AUS200", "DE30", "DJ30", "ES35", "F40", "JP225",
    "N25", "STOXX50", "SWI20", "UK100", "US2000", "US30_t", "US500", "USTEC"
  ],
  METALS: [
    "XAGUSD_t", "XAGUSDraw", "XAUUSD_t", "XAUUSDraw"
  ],
  ENERGY: [
    "BREUSD", "NGAS", "WTIUSD", "WTIUSD_t"
  ],
  COMMODITIES: [
    "CL", //Crude Oil 
    "NG", //Natural Gas 
    "ZC", //Corn 
    "ZW", //Wheat 
    "KC"
  ],
  CRYPTO_CFDs: [
    "AAVEUSDg", "ADAUSDg", "BCHUSDg", "BNBUSDg", "BTCUSD_t", "BTCUSDg",
    "DOGUSDg", "DOTUSDg", "EOSUSDg", "ETHUSD_t", "ETHUSDg", "LNKUSDg",
    "LTCUSDg", "MKRUSDg", "NERUSDg", "SOLUSDg", "XRPUSDg", "XTZUSDg"
  ]
};


// Add this helper function near the top of the file
const formatDisplayName = (symbol) => {
  return symbol
    .replace(/raw$/, '')    // Remove 'raw' from the end
    .replace(/g$/, '')      // Remove 'g' from the end
    .replace(/_t$/, '');    // Remove '_t' from the end
};

// Add a helper function to format category names
const formatCategoryName = (category) => {
  return category
    .replace(/_/g, ' ')          // Replace underscores with spaces
    .replace(/CFDs/g, 'CFDs')    // Keep CFDs in caps
    .split(' ')
    .map(word => word === 'CFDs' ? 'CFDs' : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

const MT5MarketTable2 = () => {
  const [symbols, setSymbols] = useState([]);
  const [marketData, setMarketData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isConnected, setIsConnected] = useState(true);
  const [lastUpdate, setLastUpdate] = useState(null);
  const [error, setError] = useState(null);
  // Add pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  // Add state for active tab with default value
  const [activeTab, setActiveTab] = useState('FOREX');

  // Get settings from WordPress and add debug logs
  const API_TOKEN = window.mt5MarketData.apiToken;
  const ACCOUNT_ID = window.mt5MarketData.accountId;
  const SELECTED_SYMBOLS = window.mt5MarketData.selectedSymbols || [];

  // Debug logs
  console.log('WordPress Settings:', {
    apiToken: API_TOKEN,
    accountId: ACCOUNT_ID,
    selectedSymbols: SELECTED_SYMBOLS,
    rawData: window.mt5MarketData
  });

  const API_HEADERS = {
    'auth-token': API_TOKEN,
    'Content-Type': 'application/json'
  };

  // Fetch only selected symbols
  const fetchSymbols = async () => {
    try {
      // If we have selected symbols, use those instead of fetching all
      if (SELECTED_SYMBOLS && Array.isArray(SELECTED_SYMBOLS) && SELECTED_SYMBOLS.length > 0) {
        setSymbols(SELECTED_SYMBOLS);
        return SELECTED_SYMBOLS;
      }

      // Otherwise fetch all symbols
      const symbolsResponse = await fetch(
        `https://mt-client-api-v1.london.agiliumtrade.ai/users/current/accounts/${ACCOUNT_ID}/symbols`,
        {
          method: 'GET',
          headers: API_HEADERS
        }
      );
      
      const allSymbols = await symbolsResponse.json();
      if (Array.isArray(allSymbols)) {
        setSymbols(allSymbols);
        return allSymbols;
      } else {
        console.error('Received invalid symbols data:', allSymbols);
        setSymbols([]);
        return [];
      }
    } catch (err) {
      console.error("Error fetching symbols:", err);
      setError('Failed to fetch symbols');
      setSymbols([]);
      return [];
    }
  };

  // Add new function to fetch stored data
  const fetchStoredData = async () => {
    try {
      console.log('📥 Fetching stored data from database...');
      const restUrl = window.mt5MarketData.restUrl || '/wp-json/';
      const fullUrl = `${restUrl}mt5-market-data-spreads-and-commissions/v1/latest`;
      
      console.log('Fetching from URL:', fullUrl);
      
      const response = await fetch(fullUrl);
      
      console.log('Response status:', response.status);
      console.log('Response headers:', Object.fromEntries(response.headers));
      
      if (!response.ok) {
        console.warn('⚠️ Failed to fetch stored data:', response.status, response.statusText);
        const errorText = await response.text();
        console.error('Error response:', errorText);
        return;
      }

      const { data } = await response.json();
      if (data && data.length > 0) {
        console.log(`✅ Loaded ${data.length} symbols from database`);
        console.table(data.map(item => ({
          symbol: item.product,
          ecnMinSpread: item.ecnMinSpread,
          ecnAvgSpread: item.ecnAvgSpread,
          stpMinSpread: item.stpMinSpread,
          stpAvgSpread: item.stpAvgSpread,
          timestamp: item.timestamp
        })));
        
        setMarketData(data);
        setLastUpdate(new Date(data[0].timestamp));
        setIsLoading(false);
        setIsConnected(true);
        setError(null);
      } else {
        console.log('ℹ️ No stored data available');
      }
    } catch (err) {
      console.error('❌ Error fetching stored data:', err);
    }
  };

  // Initialize symbols and start market data updates
  useEffect(() => {
    const initializeData = async () => {
      // First fetch stored data
      await fetchStoredData();
      
      // Then fetch symbols and start live updates
      const initialSymbols = await fetchSymbols();
      if (Array.isArray(initialSymbols) && initialSymbols.length > 0) {
        await fetchMarketData(initialSymbols);
      }
    };

    initializeData();
  }, []); // Run once on mount

  // Update the fetchMarketData function to merge new data with existing data
  const fetchMarketData = async (symbolsList) => {
    try {
      setIsLoading(true);
      
      const marketDataPromises = symbolsList.map(async (symbol) => {
        try {
          const [priceResponse, response1, response2, response3, response4, response5, response6, dailyResponse, weeklyResponse] = await Promise.all([
            fetch(
              `https://mt-client-api-v1.london.agiliumtrade.ai/users/current/accounts/${ACCOUNT_ID}/symbols/${symbol}/current-price?keepSubscription=false`,
              {
                method: 'GET',
                headers: API_HEADERS
              }
            ),
            fetch(
              `https://mt-client-api-v1.london.agiliumtrade.ai/users/current/accounts/${ACCOUNT_ID}/symbols/${symbol}/current-candles/1m?keepSubscription=false`,
              {
                method: 'GET',
                headers: API_HEADERS
              }
            ),
            fetch(
              `https://mt-client-api-v1.london.agiliumtrade.ai/users/current/accounts/${ACCOUNT_ID}/symbols/${symbol}/current-candles/5m?keepSubscription=false`,
              {
                method: 'GET',
                headers: API_HEADERS
              }
            ),
            fetch(
              `https://mt-client-api-v1.london.agiliumtrade.ai/users/current/accounts/${ACCOUNT_ID}/symbols/${symbol}/current-candles/15m?keepSubscription=false`,
              {
                method: 'GET',
                headers: API_HEADERS
              }
            ),
            fetch(
              `https://mt-client-api-v1.london.agiliumtrade.ai/users/current/accounts/${ACCOUNT_ID}/symbols/${symbol}/current-candles/30m?keepSubscription=false`,
              {
                method: 'GET',
                headers: API_HEADERS
              }
            ),
            fetch(
              `https://mt-client-api-v1.london.agiliumtrade.ai/users/current/accounts/${ACCOUNT_ID}/symbols/${symbol}/current-candles/1h?keepSubscription=false`,
              {
                method: 'GET',
                headers: API_HEADERS
              }
            ),
            fetch(
              `https://mt-client-api-v1.london.agiliumtrade.ai/users/current/accounts/${ACCOUNT_ID}/symbols/${symbol}/current-candles/4h?keepSubscription=false`,
              {
                method: 'GET',
                headers: API_HEADERS
              }
            ),fetch(
              `https://mt-client-api-v1.london.agiliumtrade.ai/users/current/accounts/${ACCOUNT_ID}/symbols/${symbol}/current-candles/1d?keepSubscription=false`,
              {
                method: 'GET',
                headers: API_HEADERS
              }
            ),
            fetch(
              `https://mt-client-api-v1.london.agiliumtrade.ai/users/current/accounts/${ACCOUNT_ID}/symbols/${symbol}/current-candles/1w?keepSubscription=false`,
              {
                method: 'GET',
                headers: API_HEADERS
              }
            )
          ]);

          const [priceData, response1Data, response2Data, response3Data, response4Data, response5Data, response6Data, candleData, weeklyData] = await Promise.all([
            priceResponse.json(),
            response1.json(),
            response2.json(),
            response3.json(),
            response4.json(),
            response5.json(),
            response6.json(),
            dailyResponse.json(),
            weeklyResponse.json()
          ]);

          // Validate all numerical values
          const isValidNumber = (num) => num !== null && num !== undefined && !isNaN(num) && isFinite(num);
          
          // Check if all required numerical values are valid
          const isValidData = 
            isValidNumber(response1Data?.high) &&
            isValidNumber(response1Data?.low) &&
            isValidNumber(response1Data?.close) &&
            isValidNumber(response1Data?.open) &&
            isValidNumber(response2Data?.high) &&
            isValidNumber(response2Data?.low) &&
            isValidNumber(response2Data?.close) &&
            isValidNumber(response2Data?.open) &&
            isValidNumber(response3Data?.high) &&
            isValidNumber(response3Data?.low) &&
            isValidNumber(response3Data?.close) &&
            isValidNumber(response3Data?.open) &&
            isValidNumber(response4Data?.high) &&
            isValidNumber(response4Data?.low) &&
            isValidNumber(response4Data?.close) &&
            isValidNumber(response4Data?.open) &&
            isValidNumber(response5Data?.high) &&
            isValidNumber(response5Data?.low) &&
            isValidNumber(response5Data?.close) &&
            isValidNumber(response5Data?.open) &&
            isValidNumber(response6Data?.high) &&
            isValidNumber(response6Data?.low) &&
            isValidNumber(response6Data?.close) &&
            isValidNumber(response6Data?.open) &&
            isValidNumber(candleData?.high) &&
            isValidNumber(candleData?.low) &&
            isValidNumber(candleData?.close) &&
            isValidNumber(candleData?.open) &&
            isValidNumber(weeklyData?.close) &&
            isValidNumber(weeklyData?.open); 

          if (!isValidData) {
            console.log(`Skipping update for ${symbol} - Invalid numerical data received`, {
              response1Data,
              response2Data,
              response3Data,
              response4Data,
              response5Data,
              response6Data,
              candleData,
              weeklyData
            });
            return null;
          }

          // Calculate values only if data is valid
          const marginPercentage = ((candleData.high - candleData.low) / candleData.low * 100).toFixed(2);
          const dayChange = ((candleData.close - candleData.open) / candleData.open * 100);
          const weeklyChange = ((weeklyData.close - weeklyData.open) / weeklyData.open * 100);
          const closePrice = candleData.close;
          const spread = candleData.spread || 0;
          let bid, ask;
          if (priceData && isValidNumber(priceData.bid) && isValidNumber(priceData.ask)) {
            console.log(`✅ ${symbol}: Using API bid/ask prices`, {
              bid: priceData.bid,
              ask: priceData.ask
            });
            bid = priceData.bid;
            ask = priceData.ask;
          } else {
            // Fallback calculation using close price and spread
            bid = closePrice;
            ask = closePrice + (spread / 10000);
            console.log(`ℹ️ ${symbol}: Using calculated bid/ask prices`, {
              closePrice,
              spread,
              calculatedBid: bid,
              calculatedAsk: ask
            });
          }

          // Validate the calculated values
          if (!isValidNumber(bid) || !isValidNumber(ask)) {
            console.warn(`⚠️ ${symbol}: Invalid bid/ask values`, { bid, ask });
            bid = null;
            ask = null;
          }

          // Validate calculated values
          if (!isValidNumber(marginPercentage) || !isValidNumber(dayChange) || !isValidNumber(weeklyChange)) {
            console.log(`Skipping update for ${symbol} - Invalid calculated values`, {
              marginPercentage,
              dayChange,
              weeklyChange,
              bid,
              ask
            });
            return null;
          }

          if (isValidData) {
            console.log(`✅ ${symbol}: Using fresh API data`);
            // Calculate different spread types
            const calculateSpreads = (candleData) => {
              // Get spread from candleData, ensure it's a number and not undefined/null
              const spreadPoints = parseFloat(candleData.spread) || 0;
              console.log(`Raw spread value for ${candleData.symbol}:`, candleData.spread);
              console.log(`Parsed spread points:`, spreadPoints);
              
              // Convert spread points to actual spread values
              const ecnMinSpread = spreadPoints * 0.1;  // 10% of spread
              const ecnAvgSpread = spreadPoints * 0.2;  // 20% of spread
              const stpMinSpread = spreadPoints * 0.3;  // 30% of spread
              const stpAvgSpread = spreadPoints * 0.4;  // 40% of spread

              console.log('Calculated spreads:', {
                  spreadPoints,
                  ecnMinSpread,
                  ecnAvgSpread,
                  stpMinSpread,
                  stpAvgSpread
              });

              // Return values as numbers
              return {
                  ecnMinSpread: Number(ecnMinSpread.toFixed(1)),
                  ecnAvgSpread: Number(ecnAvgSpread.toFixed(1)),
                  stpMinSpread: Number(stpMinSpread.toFixed(1)),
                  stpAvgSpread: Number(stpAvgSpread.toFixed(1))
              };
            };

            const spreads = calculateSpreads(candleData);
            console.log(`Calculated spreads for ${symbol}:`, spreads);

            // Validate that we have non-zero spreads
            if (Object.values(spreads).every(value => value === 0)) {
                console.warn(`All spread values are 0 for ${symbol}. Raw spread:`, candleData.spread);
                // Try to use fallback data
                const fallbackData = marketData.find(item => item.product === symbol);
                if (fallbackData) {
                    console.log(`Using fallback data for ${symbol}`);
                    return fallbackData;
                }
            }

            const processedData = {
                product: symbol,
                displayName: symbol,
                ...spreads,
                timestamp: priceData?.time || null
            };

            console.log(`Final processed data for ${symbol}:`, processedData);
            return processedData;
          } else {
            const fallbackData = marketData.find(item => item.product === symbol);
            console.log(`⚠️ ${symbol}: Invalid API data, using fallback data from ${fallbackData?.timestamp || 'unknown time'}`);
            return fallbackData || null;
          }
        } catch (err) {
          console.error(`Error fetching data for ${symbol}:`, err);
          // Use fallback data in case of error
          const fallbackData = marketData.find(item => item.product === symbol);
          if (fallbackData) {
            console.log(`Using fallback data for ${symbol} from ${fallbackData.timestamp}`);
            return {
              product: symbol,
              displayName: symbol,
              ecnMinSpread: fallbackData.ecnMinSpread,
              ecnAvgSpread: fallbackData.ecnAvgSpread,
              stpMinSpread: fallbackData.stpMinSpread,
              stpAvgSpread: fallbackData.stpAvgSpread,
              timestamp: fallbackData.timestamp
            };
          }
          return null;
        }
      });

      const results = await Promise.all(marketDataPromises);

      // Update state while preserving last valid data
      setMarketData(prevData => {
        const newData = results.map((result, index) => {
          if (!result) {
            // If new data is null, find and return existing data for this symbol
            const symbol = symbolsList[index];
            const existingData = prevData.find(item => item.product === symbol);
            if (existingData) {
              console.log(`📦 ${symbol}: No new data available, keeping existing data from ${existingData.timestamp}`);
              return existingData;
            }
            console.log(`❓ ${symbol}: No data available at all`);
          }
          return result;
        }).filter(data => data !== null);

        // Log summary of update
        console.log('Data Update Summary:', {
          totalSymbols: symbolsList.length,
          updatedSymbols: newData.length,
          timestamp: new Date().toISOString()
        });

        // Store the new data
        storeMarketData(newData).then(success => {
          if (success) {
            console.log('💾 Successfully stored market data to database');
          } else {
            console.error('❌ Failed to store market data to database');
          }
        });
        
        return newData;
      });
      
      setLastUpdate(new Date());
      setIsConnected(true);
      setError(null);
    } catch (err) {
      console.error("❌ Error fetching market data:", err);
      setError('Failed to fetch market data');
      setIsConnected(false);
    } finally {
      setIsLoading(false);
    }
  };

  // Set up interval for market data updates
  useEffect(() => {
    if (symbols.length === 0) return;

    const interval = setInterval(() => {
      fetchMarketData(symbols);
    }, 5000); // Update every 5 seconds

    // Cleanup interval on unmount
    return () => clearInterval(interval);
  }, [symbols]); // Depend on symbols array

  // Add debug effect to monitor marketData changes
  useEffect(() => {
    console.log('Market Data Updated:', marketData);
  }, [marketData]);

  // Calculate pagination values - Add safety checks
  const totalPages = Math.ceil((symbols?.length || 0) / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  
  // Ensure symbols is always an array
  const safeSymbols = Array.isArray(symbols) ? symbols : [];
  const currentSymbols = safeSymbols.slice(startIndex, endIndex);

  // Add function to get symbol category
  const getSymbolCategory = (symbol) => {
    for (const [category, symbols] of Object.entries(SYMBOL_CATEGORIES)) {
      if (symbols.includes(symbol)) {
        return category;
      }
    }
    return 'OTHER';
  };

  // Update the group symbols logic to only include selected symbols
  const groupedSymbols = Object.keys(SYMBOL_CATEGORIES).reduce((acc, category) => {
    acc[category] = SELECTED_SYMBOLS.filter(symbol => 
      SYMBOL_CATEGORIES[category].includes(symbol)
    );
    return acc;
  }, {});

  console.log('Grouped Symbols:', groupedSymbols);

  // Debug log for categories with symbols
  useEffect(() => {
    console.log('Active Categories:', Object.keys(groupedSymbols).filter(category => groupedSymbols[category].length > 0));
  }, [groupedSymbols]);

  const renderSparkline = (trend, dayChange) => {
    if (!trend || trend.length === 0) return null;

    const width = 100;
    const height = 20;
    const padding = 2;

    // Normalize values for SVG viewport
    const min = Math.min(...trend);
    const max = Math.max(...trend);
    const range = max - min;

    const normalizeY = (value) => {
      return height - padding - ((value - min) / range) * (height - 2 * padding);
    };

    const points = trend.map((value, index) => {
      const x = (index * (width - 2 * padding)) / (trend.length - 1) + padding;
      const y = normalizeY(value);
      return `${x},${y}`;
    }).join(' ');

    const lineColor = dayChange >= 0 ? '#22c55e' : '#ef4444';

    return (
      <svg className="w-24 h-5" viewBox={`0 0 ${width} ${height}`}>
        {/* Base line */}
        <line
          x1={padding}
          y1={normalizeY(trend[0])}
          x2={width - padding}
          y2={normalizeY(trend[0])}
          stroke="#e5e7eb"
          strokeWidth="0.5"
          strokeDasharray="2,2"
        />
        {/* Trend line */}
        <polyline
          points={points}
          fill="none"
          stroke={lineColor}
          strokeWidth="1"
        />
      </svg>
    );
  };

  const renderChange = (change) => {
    if (change === null) return '-';
    const isPositive = change > 0;
    const color = isPositive ? 'text-green-500' : 'text-red-500';
    const arrow = isPositive ? '↑' : '↓';
    return (
      <span className={color}>
        {arrow} {Math.abs(change).toFixed(2)}%
      </span>
    );
  };

  const formatPrice = (price, product) => {
    if (!price) return '-';
    
    // Format based on instrument type
    if (product === 'GBP/USD') {
      return price.toFixed(4);
    } else if (product === 'XAUUSD') {
      return price.toFixed(2);
    } else if (['WTI', 'BRENT'].includes(product)) {
      return price.toFixed(2);
    } else {
      return price.toFixed(2);
    }
  };

  // Update the Pagination component
  const Pagination = () => (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
      <div className="flex flex-1 justify-between sm:hidden">
        <button
          onClick={() => setCurrentPage(page => Math.max(1, page - 1))}
          disabled={currentPage === 1}
          className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50"
        >
          Previous
        </button>
        <button
          onClick={() => setCurrentPage(page => Math.min(totalPages, page + 1))}
          disabled={currentPage === totalPages}
          className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50"
        >
          Next
        </button>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{startIndex + 1}</span> to{' '}
            <span className="font-medium">{Math.min(endIndex, safeSymbols.length)}</span> of{' '}
            <span className="font-medium">{safeSymbols.length}</span> results
          </p>
        </div>
        <div>
          <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
            <button
              onClick={() => setCurrentPage(page => Math.max(1, page - 1))}
              disabled={currentPage === 1}
              className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:opacity-50"
            >
              Previous
            </button>
            {[...Array(totalPages)].map((_, index) => (
              <button
                key={index + 1}
                onClick={() => setCurrentPage(index + 1)}
                className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
                  currentPage === index + 1
                    ? 'z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                    : 'text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                }`}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={() => setCurrentPage(page => Math.min(totalPages, page + 1))}
              disabled={currentPage === totalPages}
              className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:opacity-50"
            >
              Next
            </button>
          </nav>
        </div>
      </div>
    </div>
  );

  // Inside the fetchMarketData function, after setting the marketData state:
  const storeMarketData = async (newData) => {
    try {
        console.log('Starting storeMarketData with data:', newData);
        const restUrl = window.mt5MarketData.restUrl || '/wp-json/';
        const nonce = window.mt5MarketData.nonce;

        console.log('WordPress settings:', {
            restUrl,
            hasNonce: !!nonce
        });

        const marketDataToStore = newData
            .filter(item => item && item.product) // Only include items with product
            .map(item => {
                console.log(`Processing item for storage:`, item);
                return {
                    product: item.product,
                    ecnMinSpread: item.ecnMinSpread,
                    ecnAvgSpread: item.ecnAvgSpread,
                    stpMinSpread: item.stpMinSpread,
                    stpAvgSpread: item.stpAvgSpread
                };
            });

        console.log('Prepared data for storage:', marketDataToStore);

        if (marketDataToStore.length === 0) {
            console.warn('No valid data to store');
            return false;
        }

        const fullUrl = `${restUrl}mt5-market-data-spreads-and-commissions/v1/store`;
        console.log('Sending request to:', fullUrl);

        const response = await fetch(fullUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-WP-Nonce': nonce
            },
            body: JSON.stringify({
                marketData: marketDataToStore
            })
        });

        console.log('Response received:', {
            status: response.status,
            ok: response.ok,
            headers: Object.fromEntries(response.headers)
        });

        if (!response.ok) {
            const errorText = await response.text();
            console.error('Error response:', {
                status: response.status,
                text: errorText
            });
            try {
                const errorData = JSON.parse(errorText);
                console.error('Parsed error data:', errorData);
            } catch (e) {
                console.error('Raw error text:', errorText);
            }
            return false;
        }

        const responseData = await response.json();
        console.log('Successfully stored data:', responseData);
        return true;

    } catch (err) {
        console.error('Error in storeMarketData:', err);
        return false;
    }
  };

  return (
    <Card className="w-full">
      {/* <CardHeader className="flex flex-row items-center justify-between">
        <CardTitle>MT5 Market Data</CardTitle>
        <div className="flex items-center gap-4 text-sm text-gray-500">
          {isConnected ? (
            <Wifi className="h-4 w-4 text-green-500" />
          ) : (
            <WifiOff className="h-4 w-4 text-red-500" />
          )}
          {lastUpdate && (
            <span>
              Last update: {lastUpdate.toLocaleTimeString()}
            </span>
          )}
          <RefreshCw 
            className={`h-4 w-4 cursor-pointer ${isLoading ? 'animate-spin' : ''}`}
            onClick={() => fetchMarketData(SELECTED_SYMBOLS)}
          />
        </div>
      </CardHeader> */}
      <CardContent>
        {error && (
          <Alert variant="destructive" className="mb-4">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}
        <div className="table-tabs-container">
          <ul className="tabs-navigation">
            {Object.keys(SYMBOL_CATEGORIES).map((category) => (
              <li key={category}>
                <a
                  onClick={() => setActiveTab(category)}
                  className={`short-trading-table__link tabs__link ${
                    activeTab === category ? 'active' : ''
                  }`}
                >
                  {formatCategoryName(category)}
                </a>
              </li>
            ))}
          </ul>
          {/* <div className="short-trading-table__nav mb-4">
            <div className="short-trading-table__links">
              {Object.keys(SYMBOL_CATEGORIES).map((category) => (
                <button
                  key={category}
                  onClick={() => setActiveTab(category)}
                  className={`short-trading-table__link tabs__link ${
                    activeTab === category ? 'active' : ''
                  }`}
                >
                  {category}
                </button>
              ))}
            </div>
          </div> */}
          <div className="tabs-content">
            {Object.entries(SYMBOL_CATEGORIES).map(([category, _]) => {
              // Get valid data count for this category
              const validDataCount = groupedSymbols[category]?.reduce((count, symbol) => {
                const data = marketData.find(item => item.product === symbol);
                const isValidData = data && 
                  data.ecnMinSpread !== null && data.ecnMinSpread !== 0 &&
                  data.ecnAvgSpread !== null && data.ecnAvgSpread !== 0 &&
                  data.stpMinSpread !== null && data.stpMinSpread !== 0 &&
                  data.stpAvgSpread !== null && data.stpAvgSpread !== 0;
                return count + (isValidData ? 1 : 0);
              }, 0);

              return (
                <div 
                  key={category} 
                  className="tab-content" 
                  data-tab={`tab-${category.toLowerCase()}`}
                  style={{ display: activeTab === category ? 'block' : 'none' }}
                >
                  <table>
                    <thead>
                      <tr className="border-b">
                        <th className="text-left p-2">Symbol</th>
                        <th className="text-right p-2">ECN Min Spread</th>
                        <th className="text-right p-2">ECN Avg Spread</th>
                        <th className="text-right p-2">STP Min Spread</th>
                        <th className="text-right p-2">STP Avg Spread</th>
                      </tr>
                    </thead>
                    <tbody>
                      {validDataCount > 0 ? (
                        groupedSymbols[category]?.map((symbol) => {
                          const data = marketData.find(item => item.product === symbol);

                          // Define what constitutes valid data
                          const isValidData = data && 
                            data.ecnMinSpread !== null && data.ecnMinSpread !== 0 &&
                            data.ecnAvgSpread !== null && data.ecnAvgSpread !== 0 &&
                            data.stpMinSpread !== null && data.stpMinSpread !== 0 &&
                            data.stpAvgSpread !== null && data.stpAvgSpread !== 0;

                          if (!isValidData) {
                            return null; // Skip rendering this row if data is invalid
                          }

                          return (
                            <tr key={symbol} className="border-b hover:bg-gray-50">
                              <td className="p-2 font-medium">
                                {formatDisplayName(data?.displayName || symbol)}
                              </td>
                              <td className="text-right p-2">
                                {data?.ecnMinSpread || '-'}
                              </td>
                              <td className="text-right p-2">
                                {data?.ecnAvgSpread || '-'}
                              </td>
                              <td className="text-right p-2">
                                {data?.stpMinSpread || '-'}
                              </td>
                              <td className="text-right p-2">
                                {data?.stpAvgSpread || '-'}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="5" className="text-center p-4 text-gray-500">
                            No valid data available for this category
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              );
            })}
          </div>
        </div>
        {/* <p className="text-sm text-gray-500 mt-4">
          Pricing is indicative. Past performance is not a reliable indicator of future results.
        </p> */}
      </CardContent>
    </Card>
  );
};

export default MT5MarketTable2;
